import React, { StrictMode } from "react";
import PartySocket from "partysocket";
import { createStore } from "tinybase";
import {
  Provider,
  useCreatePersister,
  useCreateStore,
} from "tinybase/debug/ui-react";
import {
  SortedTableInHtmlTable,
  StoreInspector,
  ValuesInHtmlTable,
} from "tinybase/debug/ui-react-dom";
import { createPartyKitPersister } from "tinybase/persisters/persister-partykit-client";
import { createLocalPersister } from "tinybase/persisters/persister-browser";
import { Buttons } from "./Buttons";
import { useRoomId } from "./hooks";
import { Share } from "./Share";
import { Alert, AlertIcon, Button } from "@chakra-ui/react";
import {
  ChakraBaseProvider,
  ChakraProvider,
  extendBaseTheme,
  theme as chakraTheme,
} from "@chakra-ui/react";
import CodeMirror, { ViewUpdate } from "@uiw/react-codemirror";
import { markdown } from "@codemirror/lang-markdown";

declare const PARTYKIT_HOST: string;

export const App = () => {
  const store = useCreateStore(() => {
    // Create the TinyBase Store and initialize the Store's data
    return createStore().setValuesSchema({
      counter: {
        type: "number",
        default: 0,
      },
      notes: {
        type: "string",
        default: "initial note from store",
      },
    });
  });

  const [roomId, createRoomId] = useRoomId();
  useCreatePersister(
    store,
    (store) => {
      if (roomId) {
        return createPartyKitPersister(
          store,
          new PartySocket({ host: PARTYKIT_HOST, room: roomId }),
          location.protocol.slice(0, -1) as "http" | "https"
        );
      }
    },
    [roomId],
    async (persister) => {
      if (persister) {
        console.log("Starting auto save in pk", persister);
        await persister.startAutoLoad();
        await persister.startAutoSave();
      } else {
        console.log("no pk persister");
      }
    }
  );

  useCreatePersister(
    store,
    (store) => {
      return createLocalPersister(store, "elater");
    },
    [],
    async (persister) => {
      if (persister) {
        console.log("Starting local auto save and load", persister);
        await persister.startAutoLoad();
        await persister.startAutoSave();
      } else {
        console.log("no local persister");
      }
    }
  );

  const value = store.getValue("notes")?.toString() || "initial note";
  const onChange = React.useCallback<
    (val: string, viewUpdate: ViewUpdate) => void
  >(
    (val, viewUpdate) => {
      console.log("val:", val);
      // setValue(val);
      store.setValues({ notes: val });
    },
    [store]
  );

  return (
    <StrictMode>
      <Provider store={store}>
        <Share roomId={roomId} createRoomId={createRoomId} />
        <Buttons />
        <div>
          <h2>Values</h2>
          <ValuesInHtmlTable />
        </div>
        <CodeMirror
          value={value}
          height="200px"
          extensions={[markdown()]}
          onChange={onChange}
        />
        <StoreInspector />
      </Provider>
    </StrictMode>
  );
};
