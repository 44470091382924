import "./index.css";
import React from "react";
import ReactDOM from "react-dom/client";
import { App } from "./App";
import {
  ChakraBaseProvider,
  extendBaseTheme,
  theme as chakraTheme,
} from "@chakra-ui/react";
import { extendTheme } from "@chakra-ui/react";

const colors = {
  brand: {
    900: "#124710",
    800: "#1a5f1a",
    700: "#1f6f1f",
    600: "#237f23",
    500: "#1d6f1d",
    400: "#2a8f2a",
    300: "#3aa63a",
    200: "#5cc65c",
    100: "#f1ffe2",
  },
};

const { Button, Alert } = chakraTheme.components;

const theme = extendBaseTheme({
  colors,
  components: {
    Button,
    Alert,
  },
});

addEventListener("load", () =>
  ReactDOM.createRoot(document.getElementById("app")!).render(
    <ChakraBaseProvider theme={theme}>
      <App />
    </ChakraBaseProvider>
  )
);
